import { TooltipPosition } from "@logex/framework/ui-core";

import { MouseCoordinates } from "./chart.types";

export function getRecommendedPosition(
    mouse: MouseCoordinates,
    overlay: HTMLElement
): TooltipPosition {
    const overlayHasBeenRendered =
        overlay &&
        overlay.clientWidth !== 0 && // cheers IE initial render
        overlay.clientHeight < 900; // cheers Chrome initial render
    const tooltipHeight = overlayHasBeenRendered ? overlay.clientHeight : 300;
    const tooltipWidth = overlayHasBeenRendered ? overlay.clientWidth : 500;

    const showOnRight = window.innerWidth > tooltipWidth + mouse.x;
    const showOnTop = 0 > tooltipHeight - mouse.y;

    switch (true) {
        case showOnRight && showOnTop:
            return "top-right";
        case !showOnRight && showOnTop:
            return "top-left";
        case showOnRight && !showOnTop:
            return "bottom-right";
        default:
            return "bottom-left";
    }
}
