import { ElementRef } from "@angular/core";

import { TooltipPosition, ExtendedConnectionPositionPair } from "@logex/framework/ui-core";

export function getConnectionPoint(
    position: TooltipPosition,
    totalOffset: number
): ExtendedConnectionPositionPair {
    switch (position) {
        case "bottom-left":
        default:
            return {
                originX: "center",
                originY: "bottom",
                overlayX: "end",
                overlayY: "top",
                offsetX: totalOffset,
                offsetY: 0,
                className: "bottom-left",
                arrowLeft: null,
                arrowRight: totalOffset,
                arrowTop: 0,
                arrowBottom: null
            };
        case "bottom-right":
            return {
                originX: "center",
                originY: "bottom",
                overlayX: "start",
                overlayY: "top",
                offsetX: -totalOffset,
                offsetY: 0,
                className: "bottom-right",
                arrowLeft: totalOffset,
                arrowRight: null,
                arrowTop: 0,
                arrowBottom: null
            };
        case "top-left":
            return {
                originX: "center",
                originY: "top",
                overlayX: "end",
                overlayY: "bottom",
                offsetX: totalOffset,
                offsetY: 0,
                className: "top-left",
                arrowLeft: null,
                arrowRight: totalOffset,
                arrowTop: null,
                arrowBottom: 0
            };
        case "top-right":
            return {
                originX: "center",
                originY: "top",
                overlayX: "start",
                overlayY: "bottom",
                offsetX: -totalOffset,
                offsetY: 0,
                className: "top-right",
                arrowLeft: totalOffset,
                arrowRight: null,
                arrowTop: null,
                arrowBottom: 0
            };
        case "left-bottom":
            return {
                originX: "start",
                originY: "center",
                overlayX: "end",
                overlayY: "top",
                offsetX: 0,
                offsetY: -totalOffset,
                className: "left-bottom",
                arrowLeft: null,
                arrowRight: 0,
                arrowTop: totalOffset,
                arrowBottom: null
            };
        case "left-top":
            return {
                originX: "start",
                originY: "center",
                overlayX: "end",
                overlayY: "bottom",
                offsetX: 0,
                offsetY: totalOffset,
                className: "left-top",
                arrowLeft: null,
                arrowRight: 0,
                arrowTop: null,
                arrowBottom: totalOffset
            };
        case "right-bottom":
            return {
                originX: "end",
                originY: "center",
                overlayX: "start",
                overlayY: "top",
                offsetX: 0,
                offsetY: -totalOffset,
                className: "right-bottom",
                arrowLeft: 0,
                arrowRight: null,
                arrowTop: totalOffset,
                arrowBottom: null
            };
        case "right-top":
            return {
                originX: "end",
                originY: "center",
                overlayX: "start",
                overlayY: "bottom",
                offsetX: 0,
                offsetY: totalOffset,
                className: "right-top",
                arrowLeft: 0,
                arrowRight: null,
                arrowTop: null,
                arrowBottom: totalOffset
            };
    }
}

export function getAlternativeOrientations(position: TooltipPosition): TooltipPosition[] {
    switch (position) {
        case "bottom-left":
        default:
            return ["bottom-right", "top-left", "top-right"];
        case "bottom-right":
            return ["bottom-left", "top-right", "top-left"];
        case "top-left":
            return ["top-right", "bottom-left", "bottom-right"];
        case "top-right":
            return ["top-left", "bottom-right", "bottom-left"];
        case "left-bottom":
            return ["left-top", "right-bottom", "right-top"];
        case "left-top":
            return ["left-bottom", "right-top", "right-bottom"];
        case "right-bottom":
            return ["right-top", "left-bottom", "left-top"];
        case "right-top":
            return ["right-bottom", "left-top", "left-bottom"];
    }
}

export function determineAutoPosition(
    target: ElementRef,
    position: TooltipPosition
): TooltipPosition {
    if (position === "auto-left-right" || position === "auto-top-bottom") {
        const offs = target.nativeElement
            ? target.nativeElement.getBoundingClientRect()
            : (target as any).getBoundingClientRect();

        const elementWidth = target.nativeElement.offsetWidth;
        const elementHeight = target.nativeElement.offsetHeight;
        let quarter = 0;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // left-right
        if (offs.left + elementWidth / 2 >= windowWidth / 2) {
            quarter += 1;
        }
        // top-bottom
        if (offs.top + elementHeight / 2 >= windowHeight / 2) {
            quarter += 2;
        }

        // note: we could do string merging here instead, but using mapping is more extensible
        if (position === "auto-left-right") {
            const mapping: TooltipPosition[] = [
                "right-bottom",
                "left-bottom",
                "right-top",
                "left-top"
            ];
            position = mapping[quarter];
        } else {
            const mapping: TooltipPosition[] = [
                "bottom-right",
                "bottom-left",
                "top-right",
                "top-left"
            ];
            position = mapping[quarter];
        }
    }

    return position;
}
