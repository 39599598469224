import { InjectionToken, Injectable, inject } from "@angular/core";
import { ChartValueTypeDictionary, ChartValueType } from "./chart.types";
import { isArray } from "lodash";
import { ILookup } from "@logex/framework/types";

/**
 * @deprecated
 */
export const DEFAULT_PALETTE = ["#E5E5E5", "#999999", "#83D8D8", "#0AB5B7", "#009295", "#007074"];

/**
 * @deprecated
 */
export const LG_CHART_COLOR_PALETTE = new InjectionToken<string[]>("LG_CHART_COLOR_PALETTE");

/**
 * @deprecated
 */
export const LG_BACKGROUND_COLOR_FOR_OVERLAPS = new InjectionToken<string>(
    "LG_BACKGROUND_COLOR_FOR_OVERLAPS"
);

/**
 * @deprecated
 */
export const DEFAULT_BACKGROUND_COLOR_FOR_OVERLAPS = "#EFEFEF";

/**
 * @deprecated
 */
export const DEFAULT_CHART_VALUE_TYPE_DICTIONARY: ChartValueTypeDictionary = {
    custom: "",
    output: "#666666",
    historic: "#E5E5E5",
    input: "#83D8D8",

    benchmark_lighter: "#3DADCB",
    benchmark: "#3E92A9",
    benchmark_darker: "#1F748B",

    forecast_lighter: "#FEE4C5",
    forecast: "#F8D2A7",
    forecast_darker: "#CFAF8E",

    efficiency_lighter: "#97C0FC",
    efficiency: "#5095F9",
    efficiency_darker: "#2177F5",

    budget_lighter: "#DDBFFF",
    budget: "#BF8CFF",
    budget_darker: "#8F55D8"
};

/**
 * @deprecated
 */
export const LG_CHART_VALUE_TYPE_DICTIONARY = new InjectionToken<string>(
    "LG_CHART_VALUE_TYPE_DICTIONARY"
);

/**
 * @deprecated
 */
export const LG_INSURER_COLORS = new InjectionToken<string>("LG_INSURER_COLORS");

/**
 * @deprecated
 */
export const INSURERS_COLORS: ILookup<string> = {
    0: "#3DADCB", // passanten
    1: "#3E92A9", // ZK
    2: "#83D8D8", // VGZ
    3: "#F8D2A7", // CZ
    4: "#1F748B", // Menzis
    5: "#B8E8E8", // Multizorg VRZ
    6: "#8F55D8", // ASR
    7: "#009295", // DSW
    8: "#0AB5B7", // De Friesland
    13: "#999999", // RZA
    14: "#E5E5E5", // wds
    16: "#BF8CFF" // caresq
};

/**
 * @deprecated
 */
@Injectable()
export class LgColorPalette {
    private _backgroundColorForOverlap = inject(LG_BACKGROUND_COLOR_FOR_OVERLAPS);
    private _chartValueTypeDictionary = inject(LG_CHART_VALUE_TYPE_DICTIONARY);
    private _insurerColors = inject<ILookup<string>>(LG_INSURER_COLORS);
    private _palette = inject(LG_CHART_COLOR_PALETTE);

    /**
     * @deprecated
     */
    getPalette(count: number): string[] {
        return this._get(count);
    }

    /**
     * @deprecated
     * @description: returns array of hexadecimal colors
     * @param colors: string containing comma delimeted colors OR array of colors,
     * - color can be either hexadecimal or key of LG_CHART_VALUE_TYPE_DICTIONARY prepended with '@'
     */
    getPaletteForColors(colors: string | string[]): string[] {
        colors = isArray(colors) ? colors : colors.split(",");

        return colors
            .map(color => color.trim())
            .map(color => {
                return color.indexOf("@") === 0
                    ? this._chartValueTypeDictionary[color.substring(1) as any]
                    : color;
            });
    }

    /**
     * @deprecated
     */
    getWholePalette(): string[] {
        return [...this._palette];
    }

    /**
     * @deprecated
     */
    getBackgroundColorForOverlappingCharts(): string {
        return this._backgroundColorForOverlap;
    }

    /**
     * @deprecated
     */
    getColorForCompareColumn(type: any): string {
        return this._chartValueTypeDictionary[type];
    }

    /**
     * @deprecated
     */
    getInsurerColorsDictionary(): ILookup<string> {
        return { ...this._insurerColors };
    }

    /**
     * @deprecated
     */
    getInsurerColor(id: number): string {
        return this._insurerColors[id];
    }

    /**
     * @deprecated
     */
    DEFAULT_PALETTE = ["#E5E5E5", "#999999", "#83D8D8", "#0AB5B7", "#009295", "#007074"];

    private _get(count: number): string[] {
        switch (count) {
            case 1:
                return [this._palette[3]];
            case 2:
                return [this._palette[1], this._palette[3]];
            case 3:
                return [this._palette[1], this._palette[3], this._palette[5]];
            case 4:
                return [this._palette[1], this._palette[3], this._palette[4], this._palette[6]];
            case 5:
                return [
                    this._palette[1],
                    this._palette[3],
                    this._palette[4],
                    this._palette[5],
                    this._palette[6]
                ];
            case 6:
            default:
                return [...this._palette];
        }
    }
}
