import { ComponentRef, ElementRef, EmbeddedViewRef, Injectable, inject } from "@angular/core";
import { GlobalPositionStrategy, Overlay } from "@angular/cdk/overlay";
import { ComponentPortal, TemplatePortal } from "@angular/cdk/portal";
import { isFunction as _isFunction, isString as _isString } from "lodash";
import { Subject, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { getConnectionPoint } from "./lg-d3-tooltip.helpers";
import {
    IOverlayResultApi,
    LgOverlayService,
    LgTooltipHolderComponent,
    TooltipPosition
} from "@logex/framework/ui-core";

export {
    // eslint-disable-next-line no-undef
    D3TooltipApi,
    // eslint-disable-next-line no-undef
    ID3TooltipContentCallback,
    // eslint-disable-next-line no-undef
    ID3TooltipOptions,
    // eslint-disable-next-line no-undef
    ID3TooltipShowOptions,
    TooltipPosition
};

/**
 * Return the content of the tooltip
 *
 * @param api the tooltip's api
 * @return the content
 */
type ID3TooltipContentCallback<C> = (api: D3TooltipApi) => string | TemplatePortal<C>;

const defaultOptions: ID3TooltipOptions = {
    position: "bottom-left",
    stay: false, // defines, if the tooltip should stay until clicked away (or click on close button ). Can be specified on show
    delayShow: 500,
    delayHide: 200,
    tooltipClass: "lg-tooltip lg-tooltip--d3",
    waitForMouse: false, // if true, the tooltip won't autohide until the user hovered over it. Only when stay is not specified. Can be given on show
    preShow: null, // if specified, call the callback before the tooltip is shown. If the function returns false, the tooltip won't show. This can be specified on show, though probably makes sense only on creation
    postShow: null, // if specified, call the callback after the tooltip is shown (before animation is done though). Get the tooltip's holder as argument
    preHide: null, // if specified, call the callback before the tooltip is hidden. If the function returns false, the tooltip won't hide. Can be specified on show
    content: null, // if specified, call the function to return the content of the tooltip. The function can either return a string, or Portal. Can be specified on show
    // alternatively, set this directly to what the return of the function can be (so a string, or object)
    target: null, // specify the element, to which should the tooltip be attached. This can be also be a function returning the target.
    offset: null, // specify the offset of the whole popup from the regular arrow position (also moves the arrow towards the popup center)
    arrowOffset: null, // specify the offset of the arrow from the regular position (moves it towards center). If offset is specified too, both are applied to the arrow
    overlayClass: ""
};

/**
 * Specifies the options for a tooltip. Note that these options can be specified both for the show call (there they have
 * precedence) and on creation time (which serves as a kind of default).
 */
interface ID3TooltipShowOptions {
    position?: TooltipPosition;
    stay?: boolean;
    tooltipClass?: string;
    waitForMouse?: boolean;
    postShow?: (holder: ElementRef) => void;
    preHide?: () => boolean;
    postHide?: () => void;
    target?: ElementRef | HTMLElement | ((api: D3TooltipApi) => ElementRef | HTMLElement);
    offset?: number;
    arrowOffset?: number;
    content?: string | TemplatePortal<any> | ID3TooltipContentCallback<any>;
    trapFocus?: boolean;
    ensureVisibility?: boolean;
    overlayClass?: string;
    panelClass?: string;
}

interface ID3TooltipOptions extends ID3TooltipShowOptions {
    /**
     * Defines the delay in ms between the hover on element, and the tooltip showing. Defaults to 500
     */
    delayShow?: number;

    /**
     * Defines the delay in ms between hovering away and the tooltip hiding. Defaults to 500
     */
    delayHide?: number;

    /**
     * If specified, call the callback before the tooltip is shown. If the function returns false, the tooltip won't show. Defaults to null
     * (This can be specified on show, though probably makes sense only on creation)
     */
    preShow?: (api: D3TooltipApi) => boolean;
}

interface D3TooltipApi {
    options(options: ID3TooltipOptions): D3TooltipApi;
    options(): ID3TooltipOptions;
    show(options?: ID3TooltipShowOptions): void;
    hide(): void;
    hideShow(): void;
    scheduleShow(): void;
    scheduleHide(): void;
    visible: boolean;
    getPortalReference<T extends ComponentRef<any> | EmbeddedViewRef<any>>(): T | null;
    destroy(): void;
    reposition(): void;
    setPosition(position: TooltipPosition): void;
    getPositionStrategy(): GlobalPositionStrategy;
    getOverlayElement(): HTMLElement;
    afterVisibilityChanged(): Observable<boolean>;
    setPositionAt(x: number, y: number, position?: TooltipPosition): void;
}

@Injectable({ providedIn: "root" })
export class LgD3TooltipService {
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);

    public create(globalOptions: ID3TooltipOptions): D3TooltipApi {
        globalOptions = { ...defaultOptions, ...globalOptions };

        let visible = false;
        let destroyed = false;
        let holderEntered = false;
        let showOptions: ID3TooltipOptions = null;
        let timer: number = null;
        let overlay: IOverlayResultApi;
        let tooltipInstance: LgTooltipHolderComponent;
        let strategy: GlobalPositionStrategy;
        const afterVisibilityChanged$ = new Subject<boolean>();

        // ---------------------------------------------------------------------------------------------
        //  Create the actual api
        // ---------------------------------------------------------------------------------------------
        const api: D3TooltipApi = {
            visible: false,

            // ---------------------------------------------------------------------------------------------
            options: <any>((options?: ID3TooltipOptions): D3TooltipApi | ID3TooltipOptions => {
                if (options === undefined) return globalOptions;
                globalOptions = { ...globalOptions, ...options };
                return api;
            }),

            // ---------------------------------------------------------------------------------------------
            show: (options?: ID3TooltipShowOptions) => {
                if (visible || destroyed) return;

                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }

                showOptions = { ...globalOptions, ...options };

                if (showOptions.preShow && showOptions.preShow.call(api, api) === false) return;

                let innerPortal, innerText;

                let content: string | TemplatePortal<any>;
                if (_isFunction(showOptions.content)) {
                    content = showOptions.content(api);
                } else {
                    content = showOptions.content;
                }

                if (_isString(content)) {
                    innerText = content;
                    innerPortal = undefined;
                } else {
                    innerText = undefined;
                    innerPortal = content;
                }

                if (!innerPortal && !innerText) return;

                const targetElement = _isFunction(showOptions.target)
                    ? showOptions.target(api)
                    : showOptions.target;

                const elementRef: ElementRef =
                    targetElement instanceof ElementRef
                        ? targetElement
                        : new ElementRef(targetElement);

                holderEntered = false;

                strategy = this._overlay.position().global();

                const useBackground = showOptions.stay || showOptions.waitForMouse;
                overlay = this._overlayService.show({
                    class: showOptions.overlayClass,
                    panelClass: showOptions.panelClass,
                    onClick: api.hide,
                    hasBackdrop: useBackground,
                    trapFocus: useBackground && showOptions.trapFocus,
                    sourceElement: useBackground && showOptions.trapFocus ? elementRef : null,
                    positionStrategy: strategy,
                    scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
                });

                const portal = new ComponentPortal(LgTooltipHolderComponent);
                tooltipInstance = overlay.overlayRef.attach(portal).instance;
                tooltipInstance.tooltipClass = showOptions.tooltipClass;
                tooltipInstance.animationEnabled = false;
                tooltipInstance.message = innerText;
                tooltipInstance.portal = innerPortal;
                tooltipInstance.ensureVisibility = showOptions.ensureVisibility;

                const hidden: Subject<void> = new Subject();

                tooltipInstance.requestClose.pipe(takeUntil(hidden)).subscribe(api.hide);
                tooltipInstance.requestReposition.pipe(takeUntil(hidden)).subscribe(() => {
                    strategy.apply();
                });

                tooltipInstance.hover
                    .pipe(takeUntil(hidden), takeUntil(tooltipInstance.beforeHidden()))
                    .subscribe(over => {
                        if (over) {
                            api.scheduleShow();
                        } else {
                            api.scheduleHide();
                        }
                    });

                let originalOverlay = overlay;
                function detach(): void {
                    if (originalOverlay) {
                        const store = originalOverlay;
                        originalOverlay = null;
                        hidden.next();
                        hidden.complete();
                        if (store.overlayRef.hasAttached) {
                            store.overlayRef.detach();
                        }
                        store.hide();
                    }
                }

                tooltipInstance.afterHidden().pipe(takeUntil(hidden)).subscribe(detach);

                overlay.overlayRef.detachments().pipe(takeUntil(hidden)).subscribe(detach);

                if (showOptions.postShow) {
                    showOptions.postShow.call(api, null);
                }

                visible = true;
                api.visible = true;
                afterVisibilityChanged$.next(true);
            },

            // ---------------------------------------------------------------------------------------------
            hide: () => {
                if (!visible) return;
                if (showOptions.preHide && showOptions.preHide.call(api) === false) return;

                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }

                tooltipInstance.hide();

                overlay = null;
                tooltipInstance = null;
                visible = false;
                api.visible = false;
                if (showOptions.postHide) showOptions.postHide();
                afterVisibilityChanged$.next(false);
            },

            // ---------------------------------------------------------------------------------------------
            hideShow: () => {
                if (!visible) {
                    api.show();
                    return;
                }

                if (showOptions.preHide && showOptions.preHide.call(api) === false) return;

                tooltipInstance.hide();

                overlay = null;
                tooltipInstance = null;
                visible = false;
                api.visible = false;

                api.show();
            },

            // ---------------------------------------------------------------------------------------------
            scheduleShow: () => {
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }

                if (!visible) {
                    timer = window.setTimeout(api.show, globalOptions.delayShow);
                } else {
                    holderEntered = true;
                }
            },

            // ---------------------------------------------------------------------------------------------
            scheduleHide: () => {
                if (showOptions && showOptions.stay) return;
                if (overlay && overlay.overlayRef.backdropElement && !holderEntered) return;

                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }
                if (visible) {
                    timer = window.setTimeout(api.hide, globalOptions.delayHide);
                }
            },

            // ---------------------------------------------------------------------------------------------
            getPortalReference: () => {
                if (!tooltipInstance) return null;
                return tooltipInstance.portalReference;
            },

            // ---------------------------------------------------------------------------------------------
            afterVisibilityChanged: () => afterVisibilityChanged$.asObservable(),

            // ---------------------------------------------------------------------------------------------
            destroy: () => {
                api.hide();

                if (overlay) {
                    // hide immediately
                    overlay.overlayRef.detach();
                    overlay.hide();
                    overlay = null;
                }

                destroyed = true;
                afterVisibilityChanged$.complete();
            },

            reposition: (): void => {
                if (overlay && overlay.overlayRef) {
                    overlay.overlayRef.updatePosition();
                }
            },

            setPosition: (position: TooltipPosition): void => {
                globalOptions.position = position;

                if (tooltipInstance) {
                    tooltipInstance.setPosition(
                        getConnectionPoint(
                            position,
                            (globalOptions.offset || 0) + (globalOptions.arrowOffset || 0)
                        )
                    );
                }
            },

            getPositionStrategy: (): GlobalPositionStrategy => {
                return strategy;
            },

            getOverlayElement: (): HTMLElement => {
                return overlay.overlayRef.overlayElement;
            },

            setPositionAt: (x: number, y: number, position?: TooltipPosition) => {
                if (position) {
                    globalOptions.position = position;

                    if (tooltipInstance) {
                        tooltipInstance.setPosition(
                            getConnectionPoint(
                                position,
                                (globalOptions.offset || 0) + (globalOptions.arrowOffset || 0)
                            )
                        );
                    }
                }

                if (!tooltipInstance) return;
                const [horizontal, vertical] = globalOptions.position!.split("-");

                if (horizontal === "top") {
                    strategy.bottom(`calc( 100vh - ${y - 8}px)`);
                } else {
                    strategy.top(y + 20 + "px");
                }

                if (vertical === "left") {
                    strategy.right(`calc( 100vw - ${x + 3}px)`);
                } else {
                    strategy.left(x + 2 + "px");
                }

                if (overlay && overlay.overlayRef) {
                    overlay.overlayRef.updatePosition();
                }
            }
        };

        return api;
    }
}
